.caixaRegistro {
  /* width: 100%; */
  margin-top: 50px;
  padding: 96px;
  background: #f0f0f5;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0, 1);
  border-radius: 8px;
  /* justify-content: center; */
  /* align-items: center; */
}
