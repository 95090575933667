.login-container {
  /* position: absolute; */
  margin-top: 50px;
  box-shadow: 0px 0px 10px #3c518681;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
form input {
  color: #3b5998;
}
#erro {
  color: #dd4b39;
}
