.espaco {
  height: 100vh;
  width: 100vw;
}

.text-larger {
  font-size: x-large;
}
.perfil-size {
  width: 300px;
}
