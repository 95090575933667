.colunas {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 8px !important;
  background-color: white;
  display: flex;
}

.imagens {
}
