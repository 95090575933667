h1,
h2,
h3,
h4,
h5 {
  font-family: SherrySwash-bold, Calibri, sans-serif !important;
  color: #3c5186;
  white-space: pre-line;
}

.text {
  text-align: justify;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
  color: #3c5186;
  white-space: pre-line;
}

.text-center {
  text-align: center !important;
}

.text-medium {
  font-size: larger;
}

.display-flex {
  display: flex;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.ref {
  color: #3c5186;
  color: #9b72aa;
  color: #c6b4ce;
  color: #fff5de;
}

.text-small {
  font-size: small;
}
