#logo {
  width: 200px;
  height: 100px;
}

#nav {
  margin-top: -10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px #3c518681;
  border-radius: 10px;
  background-color: #fff5de;
}

body {
  background-color: #fff5de;
}
.nav-text {
  font-family: SherrySwash;
  font-size: x-large;
  color: #3c5186 !important;
}

.ref {
  color: #3c5186;
  color: #9b72aa;
  color: #c6b4ce;
  color: #fff5de;
}
